/* eslint-disable react/prop-types */
import React, {useEffect } from "react"

export function AlertMessage(props) {
  useEffect(() => {
    document.getElementById("alertID").style.display = "block"
    setTimeout(() => {
      document.getElementById("alertID").style.display = "none"
    }, 5000)
  })

  return (
    <div id="alertID">
      <center>
        <div className={props.classes + " text-center justify-content-center"}>
          <div className={props.variant}>
            <h4 className="alert-heading p-1">{props.message}</h4>
            <p>{props.smallMsg}</p>
            </div>
        </div>
      </center>
    </div>
  )
}
