import React from "react"
import ContactUs from "../components/supportContactUs"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contactus = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Support - Contact us | Tata Studi"
          lang="en"
          description="Do you have any other questions and want to know about what special for you? Just fill out the Contact us form & we'll get back to you as soon as possible."
        />
        <div>
          <div className="pageBanner supportPage bannerText">
            <h1 className="displayNone">Contact Us</h1>
            <h2>Support - Contact Us</h2>
          </div>
        </div>
        <ContactUs />
      </Layout>
    </div>
  )
}

export default contactus
