// export default {
//   OTPURL: "https://cms-dev.tatastudi.com/studiapi/otp-send.php",
//   VERIFYURL: "https://cms-dev.tatastudi.com/studiapi/contact-form.php",
//   SFDCAPI: "https://cms-dev.tatastudi.com/studiapi/formAndSfdc.php",
//   AFFILIATEAPI: "https://cms-dev.tatastudi.com/studiapi/affiliateForm.php",

//   FORMSAPI: "https://cms-dev.tatastudi.com/studiapi/tceForms.php"
// }

// Comment the below export when deploying on test and comment the above one when deploying on prod.

export default {
  OTPURL: "https://cms.tatastudi.com/studiapi/otp-send.php",
  VERIFYURL: "https://cms.tatastudi.com/studiapi/contact-form.php",
  SFDCAPI: "https://cms.tatastudi.com/studiapi/formAndSfdc.php",
  AFFILIATEAPI: "https://cms.tatastudi.com/studiapi/affiliateForm.php",

  FORMSAPI: "https://cms.tatastudi.com/studiapi/tceForms.php",
  AFFAPI: "https://cms.tatastudi.com/studiapi/tceAffForms.php",
  OTPAPI: "https://cms.tatastudi.com/studiapi/newOtpApi.php",

  DSAFORMSAPI: "https://cms.tatastudi.com/studiapi/dsaForms.php"
}
