//TEST CREDENTIALS

// export default {
//   AUTHURL: "https://test.salesforce.com/services/oauth2/token",
//   LEADURL:
//     "https://tce-sokratidev--sokratidev.my.salesforce.com/services/apexrest/createsecureLead",
// }

//PROD CREDENTIALS

export default {
  AUTHURL: "https://login.salesforce.com/services/oauth2/token",
  LEADURL:
    "https://tataclassedgestudi.my.salesforce.com/services/apexrest/createsecureLead",
}

//EXPORT ONE ACCORDING TO THE REQUIREMENT AND COMMENT THE OTHER.
